<template>
    <div id="merchant-view">
      <v-row>
        <v-col
          cols="12"
        >
          <a @click="$router.back()">Back</a>
  
          <v-card>
            <v-card-text>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="filter.created_at_from"
                  type="date"
                  label="Day Start"
                  class="mr-2"
                />
  
                <v-text-field
                  v-model="filter.created_at_to"
                  type="date"
                  label="Day End"
                  class="mr-2"
                />
  
                <v-chip
                  class="cursor-pointer"
                  @click="setDates(-1, -1)"
                >
                  Yesterday
                </v-chip>
  
                <v-chip
                  class="cursor-pointer"
                  @click="setDates(-6, -1)"
                >
                  Last 7 days
                </v-chip>
  
                <v-btn
                  color="success"
                  :loading="loading"
                  @click="exportSalesReport"
                >
                  Export
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
  
<script>
import useMerchantSalesReport from './useMerchantSalesReport';
  
export default {
  setup() {
    const {
      exportSalesReport, loading, filter, setDates
    } = useMerchantSalesReport()

    return {
      exportSalesReport,
      loading,
      filter,
      setDates,
    }
  },
}
</script>
  