import { ref } from '@vue/composition-api'
import moment from 'moment'
import { reportService } from '../../services'

// const cached = []

export default function useMerchantSalesReport() {
  const loading = ref(false)
  const filter = ref({
    created_at_from: '',
    created_at_to: '',
  })

  const exportSalesReport = async () => {
    loading.value = true

    await reportService.exportSalesReport(filter.value).finally(() => {
      loading.value = false
    })
  }

  const setDates = (fromDays = -1, toDays = -1) => {
    filter.value.created_at_from = moment().add(fromDays, 'days').startOf('day').format('YYYY-MM-DD')
    filter.value.created_at_to = moment().add(toDays, 'days').endOf('day').format('YYYY-MM-DD')
  }

  return {
    loading,
    setDates,
    filter,
    exportSalesReport,
  }
}
